import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@/components/Layout'
import ConditionalWrapper from '@/components/ConditionalWrapper'

// Example, see https://github.com/scttcper/gatsby-casper/blob/master/src/templates/post.tsx

interface DefaultPageProps {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        title: string
        subTitle?: string
        tagline?: string
        isArticle?: boolean
        hero: any
      }
    }
  }
}

const DefaultPage = ({ data }: DefaultPageProps) => {
  const pageInfo = data.markdownRemark
  const fm = data.markdownRemark.frontmatter

  return (
    <Layout
      pageTitle={fm?.title || 'Jiu-Jitsu Yukio Tani Aalst'}
      heroTitle={fm?.title}
      heroSubTitle={fm?.subTitle}
      heroTagLine={fm?.tagline}
      heroImage={fm?.hero}
    >
      <ConditionalWrapper
        condition={fm.isArticle || false}
        wrapper={(children) => (
          <article className="row justify-content-md-center">
            <div className="col-sm col-md-8 mb-5">{children}</div>
          </article>
        )}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: pageInfo.html,
          }}
        ></div>
      </ConditionalWrapper>
    </Layout>
  )
}

export default DefaultPage

export const defaultPageQuery = graphql`
  query defaultPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subTitle
        tagline
        isArticle
        hero {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`
