/**
 * Checks if condition is true and if so, render the wrapper too
 */

interface WrapperProps {
  condition: boolean
  wrapper: Function
  children: React.ReactNode
}

const ConditionalWrapper = ({ condition, wrapper, children }: WrapperProps) =>
  condition ? wrapper(children) : children

export default ConditionalWrapper
